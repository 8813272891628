#penumpang-page {
    background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

    #section-breadcrumb {
        padding: 108px 106px 0px;
    }

    #section-table {
        padding-left: 106px;
        padding-right: 106px;
        padding-bottom: 100px;
        padding-top: 32px;

        .card {
            padding: 24px;
            box-shadow: none;
            border: 1px solid $netral-3;

            h5 {
                margin-bottom: 32px;
            }

            #section-filter {
                .row {
                    .col {
                        .btn {
                            margin-left: 24px;
                        }

                        .icon-plus {
                            padding-block: 0.5rem;
                            display: flex;
                            align-items: center;
                            gap: 0.375rem;
                        }
                    }
                }
            }

            #section-warning {
                margin-bottom: 32px;

                div {
                    padding: 8px 16px;
                    background: $warning-surface;
                }

            }
        }
    }
}


#tambah-penumpang-page {
    background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

    #section-breadcrumb {
        padding: 108px 106px 0px;
    }

    #section-form {
        padding-left: 106px;
        padding-right: 106px;
        padding-bottom: 100px;
        padding-top: 32px;

        .card {
            padding: 24px;
            box-shadow: none;
            border: 1px solid $netral-3;

            h5 {
                margin-bottom: 32px;
            }

            .form-control {
                background-color: #fff !important;
            }

            .input-group {
                input {
                    border: 0px;
                }

                .form-control {
                    background-color: #fff !important;
                    border-right: 0px;
                }
            }
        }
    }

    #section-form-2 {
        padding-left: 106px;
        padding-right: 106px;
        padding-bottom: 100px;
        padding-top: 32px;

        .card {
            padding: 24px;
            box-shadow: none;
            border: 1px solid $netral-3;

            h5 {
                margin-bottom: 32px;
            }

            .form-control {
                &:disabled {
                    background-color: $netral-2 !important;
                    color: $netral-6!important;
                    border: 1px solid $netral-4 !important; 
                }
            }
            .input-group {
                .form-control{
                    border-right: 0px;
                    background-color: $netral-2 !important;
                    border-color: $netral-4 !important;
                }
                .form-icon {
                    color: $netral-8 !important;
                    padding: 0px;
                }
                .input-group-text {
                    border-color: $netral-4 !important;
                }
            }
        }
    }
}

#otp-penumpang {

    .content {
        padding: 24px 24px 10px 24px;
        box-shadow: none;
        margin: 0px;
    }

    .btnProses {
        padding: 8px 16px;
    }
}

#upload-page {
    background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

    #section-breadcrumb {
        padding: 108px 106px 0px;
    }

    #section-card {
        padding-left: 106px;
        padding-right: 106px;
        padding-bottom: 100px;
        padding-top: 32px;

        .card {
            padding: 24px;
            box-shadow: none;
            border: 1px solid $netral-3;

            h5 {
                margin-bottom: 32px;
            }

            .dropzone{
                height: 240px;
                border-radius: 4px;
                div {
                    margin-top: 100px;
                }    
            }

        }
    }
}
