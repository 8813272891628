#detail-pesanan-page {
  background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

  #section-breadcrumb {
    margin: 7.688rem 6.25rem 0;
    padding: 0;
  }

  #detail-pesanan-body {
    margin: 0 6.25rem 0;

    .btn-copy {
      border: 1px solid $netral-1 !important;
      color: $netral-10 !important;
      background-color: $netral-1 !important;
      padding: 0px;

      &:hover {
        background-color: $netral-1 !important;
        color: $netral-4 !important;
        box-shadow: none;
      }
    }

    .card {
      padding: 24px;
      box-shadow: none;
      border: 1px solid $netral-3;

      .accordion {
        margin-bottom: 24px;
        background-color: $netral-1;
        border-radius: 10px 10px 10px 10px;
        font-weight: bold;
        color: $netral-10 !important;

        .accordion-button {
          border: 1px solid $netral-5;
          font-weight: bold;
          padding: 14px 24px;
          color: $netral-10 ;
        }

        .accordion-body {
          padding: 12px 36px;

          .row {
            margin-bottom: 8px;
          }

          hr {
            margin: 0px 0px 8px 0px;
          }
        }
      }
    }
  }
}
