//button color
.btn-primary {
	border: 1px solid $primary !important;
	background-color: $primary !important;
	background-image: none !important;
    color: $netral-1 !important;

	&:hover {
		background-color: $primary-hover !important;
	}

	&:active {
		background-color: $primary-pressed!important;
	}

	&:disabled {
		background-color: $netral-3 !important;
    	border-color: $netral-4 !important;
		color: $netral-5 !important;
	}
}

.btn-outline-primary {
	border: 1px solid  $primary  !important;
	color:  $primary  !important;
	background-color: $netral-1 !important;

	&:hover {
		background-color: $primary-hover !important;
		color: $netral-1 !important;
	}
}

.btn-warning {
	border: none !important;
	background-color: $warning !important;
	color: $netral-1 !important;

	&:hover {
		background-color: $warning-hover !important;
	}

	&:active {
		background-color: $warning-pressed !important;
	}

	&:disabled {
		background-color: $netral-3 !important;
    	border-color: $netral-4 !important;
		color: $netral-5 !important;
	}
}

.btn-outline-warning {
	border: 1px solid $warning-border !important;
	color: $warning-border !important;
	background-color: $netral-1 !important;

	&:hover {
		background-color: $warning-hover !important;
		color: $netral-1 !important;
	}
}

.btn-success {
	border: none !important;
	background-color: $success !important;
	color: $netral-1 !important;

	&:hover {
		background-color: $success-hover !important;
	}

	&:active {
		background-color: $success-pressed !important;
	}

	&:disabled {
		background-color: $netral-3 !important;
    	border-color: $netral-4 !important;
		color: $netral-5 !important;
	}
}

.btn-outline-success {
	border: 1px solid $success-border !important;
	color: $success-border !important;
	background-color: $netral-1 !important;

	&:hover {
		background-color: $success-hover !important;
		color: $netral-1 !important;
	}
}

.btn-danger {
	border: none !important;
	background-color: $danger !important;
	color: $netral-1 !important;

	&:hover {
		background-color: $danger-hover !important;
	}

	&:active {
		background-color: $danger-pressed !important;
	}

	&:disabled {
		background-color: $netral-3 !important;
    	border-color: $netral-4 !important;
		color: $netral-5 !important;
	}
}

.btn-outline-danger {
	border: 1px solid $danger-border !important;
	color: $danger-border !important;
	background-color: $netral-1 !important;

	&:hover {
		background-color: $danger-hover !important;
		color: $netral-1 !important;
	}
}

.btn-info {
	border: none !important;
	background-color: $info !important;
	color: $netral-1 !important;

	&:hover {
		background-color: $info-hover !important;
	}

	&:active {
		background-color: $info-pressed !important;
	}

	&:disabled {
		background-color: $netral-3 !important;
    	border-color: $netral-4 !important;
		color: $netral-5 !important;
	}
}

.btn-outline-info {
	border: 1px solid $info-border !important;
	color: $info-border !important;
	background-color: $netral-1 !important;

	&:hover {
		background-color: $info-hover !important;
		color: $netral-1 !important;
	}
}

.btn-outline-netral-4{
	border: 1px solid $netral-4 !important;
	color: $netral-4 !important;
	background-color: $netral-1 !important;
	&:hover {
		background-color: $primary-hover !important;
		color: $netral-1 !important;
	}
}

.btn-outline-netral-4{
	border: 1px solid $netral-4 !important;
	color: $netral-4 !important;
	background-color: $netral-1 !important;
	&:hover {
		background-color: $primary-hover !important;
		color: $netral-1 !important; 
	}
}


// button size
.btn-xs {
	padding: 4px 10px !important;
	border-radius: 4px !important;
	font-size: 12px !important;
	line-height: 15px !important;
}

.btn-sm {
	border-radius: 4px !important;
	padding: 6px 12px !important;
	font-size: 14px;
	line-height: 22px;
}

.btn-lg {
	padding: 8px 16px !important;
	border-radius: 4px !important;
	font-size: 16px  !important;
	line-height: 22px  !important;
}

.btn {
	box-shadow: none;
	font-weight: 500;

	// gradient button on hover transition
	&[class*='bg-gradient-'] {
		transition: all 0.2s ease;

		&:hover {
			transform: translateY(-2px);
		}
	}

	// Icon button padding
	&.btn-icon {
		padding: 0.5rem;
	}

	&.btn-sm {
		&.btn-icon {
			padding: 0.5rem;
			line-height: 0.5;
		}
	}

	&.btn-lg {
		&.btn-icon {
			padding: 1rem;
			line-height: 0.75;
		}
	}

	&:focus,
	&.focus,
	&:active,
	&.active {
		outline: none;
		box-shadow: none;
	}

	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled).active:focus {
		box-shadow: none !important;
	}

	// feather icons inside btn
	.feather {
		vertical-align: bottom;
	}
}