// These variables will be passed to bootstrap.
// Any variables that are also used in bootstap will effectively
// overwrites bootstrap default values.
// For reference, bootstap complete variables list should be available
// here: "node_modules/bootstrap/scss/_variables.scss"
//
// NOTE:
// gradient is background-image not a color, substituting a color
// for gradient will result in error

// React Bootstrap Variable

$font-family-sans-serif: "DM Sans", sans-serif !default;
$font-family-monospace: "Fira Code", monospace !default;

$main-color: #1879C5 !default;
$bg-dark: #252b31 !default;
$bg-light: #fafcff !default;
$text-dark: #545455 !default;

$btn-hover-color: #4aaad6 !default;
$btn-active-color: #257599 !default;
$btn-disable-color: #76b5d2 !default;
$netral-50: #afb7c4;
$netral-00: #f3f3f3;

$enable-gradients: true;
// $primary: $main-color;
$prime-gradient: linear-gradient(135deg, #12C8B9 0%, #1296FC 100%) !default;

// $success: #21C032;
// $danger: #FF404C;
// $warning: #FFB72B;
// $info: #4065E9;

$neutral-10: #FFFFFF;
$neutral-20: #F5F5F5;
$neutral-30: #DDE1E7;
$neutral-40: #E0E0E0;
$neutral-50: #C2C2C2;
$neutral-60: #9E9E9E;
$neutral-70: #909090;
$neutral-80: #616161;
$neutral-90: #404040;
$neutral-100: #191C21;

// DCN Variables

$primary : #17488D;
$primary-surface : #D1DAE8;
$primary-border : #B2C2D9;
$primary-hover : #133C75;
$primary-pressed : #0B2446;

$secondary : #FECF5B;
$secondary-surface : #FFF5DE;
$secondary-border : #FFEFC8;
$secondary-hover : #D4AC4C;
$secondary-pressed : #7F672D;

$success: #31AC3D;
$success-surface: #D6EED8;
$success-border: #BAE3BE;
$success-hover: #298F33;
$success-pressed: #18561E;

$danger: #FF1818;
$danger-surface: #FFD1D1;
$danger-border: #EF6E6E;
$danger-hover: #D41414;
$danger-pressed: #800C0C;

$warning: #FFB72B;
$warning-surface: #FFF1D5;
$warning-border: #F4C871;
$warning-hover: #D49824;
$warning-pressed: #805B15;

$info: #1363DF;
$info-surface: #D0E0F9;
$info-border: #78A6EB;
$info-hover: #1052BA;
$info-pressed: #09316F;

$netral-1 : #fbfbfb;
$netral-2 : #f7f7f7;
$netral-3 : #f1f1f1;
$netral-4 : #D7D7D7;
$netral-5 : #CECECE;
$netral-6 : #B1B1B1;
$netral-7 : #909090;
$netral-8 : #7F7F7F;
$netral-9 : #646464;
$netral-10: #353535;

$shadow-vs : rgba(0, 0, 0, 0.1);
$shadow-s : rgba(0, 0, 0, 0.1);
$shadow-m : rgba(0, 0, 0, 0.15);
$shadow-b : rgba(0, 0, 0, 0.2);
$shadow-vb : rgba(255, 255, 255, 0.25);

// $nx-colors: (
//   "primary": $main-color,
//   "success": $success,
//   "info": $info,
//   "warning": $warning,
//   "danger": $danger,
//   "neutral-10": $neutral-10,
//   "neutral-20": $neutral-20,
//   "neutral-30": $neutral-30,
//   "neutral-40": $neutral-40,
//   "neutral-50": $neutral-50,
//   "neutral-60": $neutral-60,
//   "neutral-70": $neutral-70,
//   "neutral-80": $neutral-80,
//   "neutral-90": $neutral-90,
//   "neutral-100": $neutral-100
// );

$dcn-colors: (
  "primary"      : $primary,
  "primary-surface"   : $primary-surface,
  "primary-border"    : $primary-border ,
  "primary-hover"     : $primary-hover,
  "primary-presed"    : $primary-pressed,
  "secondary"    : $secondary,
  "secondary-surface" : $secondary-surface,
  "secondary-border"  : $secondary-border ,
  "secondary-hover"   : $secondary-hover,
  "secondary-presed"  : $secondary-pressed,
  "info"    : $info,
  "info-surface" : $info-surface,
  "info-border"  : $info-border ,
  "info-hover"   : $info-hover,
  "info-presed"  : $info-pressed,
  "warning"    : $warning,
  "warning-surface" : $warning-surface,
  "warning-border"  : $warning-border ,
  "warning-hover"   : $warning-hover,
  "warning-presed"  : $warning-pressed,
  "danger"    : $danger,
  "danger-surface" : $danger-surface,
  "danger-border"  : $danger-border ,
  "danger-hover"   : $danger-hover,
  "danger-presed"  : $danger-pressed,
  "success"    : $success,
  "success-surface" : $success-surface,
  "success-border" : $success-border ,
  "success-hover" : $success-hover,
  "success-presed" : $success-pressed,
  "netral-1" : $netral-1 ,
  "netral-2" : $netral-2 ,
  "netral-3" : $netral-3 ,
  "netral-4" : $netral-4 ,
  "netral-5" : $netral-5 ,
  "netral-6" : $netral-6 ,
  "netral-7" : $netral-7 ,
  "netral-8" : $netral-8 ,
  "netral-9" : $netral-9 ,
  "netral-10": $netral-10
);

$line-clamps: 1, 2, 3, 4, 5;
