.main-text-rule {
    font-style: normal;
    font-weight: 400;
    color: #000000;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

// Heading Tags

h1 {
    @extend .main-text-rule;
    @include font-size(58px);
    line-height: 78px;
    letter-spacing: -0.015em !important;
}

h2 {

    @extend .main-text-rule;
    // font-size: 48px;
    @include font-size(48px);
    line-height: 69px;
    letter-spacing: -0.015em !important;

}

h3 {
    @extend .main-text-rule;
    // font-size: 40px;
    @include font-size(40px);
    line-height: 55px;

}

h4 {
    @extend .main-text-rule;
    // font-size: 28px;
    @include font-size(28px);
    line-height: 35px;
    letter-spacing: 0.0025em !important;

}

h5 {
    @extend .main-text-rule;
    // font-size: 24px;
    @include font-size(24px);
    line-height: 23px;
}

h6 {
    @extend .main-text-rule;
    // font-size: 20px;
    @include font-size(20px);
    line-height: 19px;
    letter-spacing: 0.0015em !important;

}

.text-body-1 {
    @extend .main-text-rule;
    // font-size: 18px;
    @include font-size(18px);
    line-height: 26px;
    letter-spacing: 0.0015em !important;
}

.text-body-2 {
    @extend .main-text-rule;
    // font-size: 14px;
    @include font-size(14px);
    line-height: 20px;
    letter-spacing: 0.0015em !important;
}

.text-body-3 {
    @extend .main-text-rule;
    // font-size: 12px;
    @include font-size(12px);
    line-height: 20px;
    letter-spacing: 0.0015em !important;
}

// Class Button

.text-btn-1 {
    @extend .main-text-rule;
    // font-size: 14px;
    @include font-size(14px);
    line-height: 22px;
}

.text-btn-2 {
    @extend .main-text-rule;
    // font-size: 12px;
    @include font-size(12px);
    line-height: 15px;
    letter-spacing: 0.0125em !important;
}

.text-btn-3 {
    @extend .main-text-rule;
    // font-size: 10px;
    @include font-size(10px);
    line-height: 15px;
    letter-spacing: 0.0125em !important;
}

