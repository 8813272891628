#others {
  background: $netral-1 !important;
  #others-page {
    padding: 8rem 7vw 4rem;
    .nav-link.active:hover {
      background-color: transparent !important;
    }
    text-align: justify;
    img {
      width: 100%;
      height: auto
    }
    .card {
      .card-body{
        .job{
          text-decoration: underline !important;
        }
      }
    }
  }
}



