#pelunasan-page {
    background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

    #section-breadcrumb {
        padding: 108px 106px 0px;
    }
    #pelunasan-body {
        padding-top: 54px;
        padding-bottom: 100px;
        padding-right: 100px;
        padding-left: 100px;

        input:disabled {
            background-color: $netral-2 !important;
            border: 1px solid $netral-4;

        }

        input {
            background-color: white !important;
        }

        .form-control {
          height: 75px;
          text-align: center;
          font-size: 50px;
        }

        .card {
            padding: 24px;
        }
    }
    #section-table {
        padding-left: 106px;
        padding-right: 106px;
        padding-bottom: 100px;
        padding-top: 32px;

        .card {
            padding: 24px;
            box-shadow: none;
            border: 1px solid $netral-3;

            h5 {
                margin-bottom: 32px;
            }

            .btn-otp{
                min-width: fit-content;
            }

        }
    }
}

#pelunasan-modal {
    margin-top: 150px !important;
    display: grid;
    justify-content: center;
    text-align: justify;

    .modal-content {
        width: 929px !important;
        height: 166px !important;
    }
}
#pelunasan-modal-2 {
    margin-top: 150px !important;
    display: grid;
    justify-content: center;
    text-align: justify;

}






