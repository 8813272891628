.form-control {
  border-radius: 4px;

  background-color: #FFFFFF !important;
  border: 1px solid $neutral-40 !important;

  &:disabled {
    background: $netral-2 !important;
    border: 1px solid $netral-4 !important;
    color: $netral-6 !important;
  }

  &:focus,
  &:active {
    box-shadow: none;
  }

  &.is-valid {
    border-color: $success !important;
  }

  &.is-invalid {
    border-color: $danger !important;

    &+.input-group-text {
      border-color: $danger !important;
    }
  }
}

.form-control,
.form-control::placeholder {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  letter-spacing: 0.25px;
  color: $neutral-70 !important;
}

.form-control::placeholder {
  color: $neutral-40 !important;
}

.dropdown-search-item {
  max-height: 12.5rem;
  overflow-y: auto;
}
