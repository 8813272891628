#credentials {
    background-color: white;

    .carousel {
        height: 100%;

        .carousel-inner {
            height: 100%;
            .carousel-item{
                div:first-of-type{
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    min-height: 100vh;
                }
                .carousel-caption {
                    position: absolute;
                    right: 15%;
                    bottom: 70%;
                    top: 30%;
                    left: 15%;
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                    color: #fff;
                    text-align: left;       
                }
            }
        }

        .carousel-indicators {
            margin-bottom: 19rem;
        }
       
    }

    .form-input {
        background-color: white !important;
    }

    .input-group {
        .form-control {
            background-color: #fff !important;
            border-right: 0px !important;
        }
    }

    #input-login {
        padding: 2rem 2rem ;
    }

    #input-daftar {
        padding: 2rem 2rem ;
    }
}

;