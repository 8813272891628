.flight-timeline {
  display: flex;
  // align-items: center;
  gap: 2.75rem;

  .flight-info {
    display: flex;
    align-items: center;
    gap: 1.25rem;

    .avatar {
      height: 43px;
      width: 43px;
      border-radius: 50%;
      object-fit: contain;
    }

    .company-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.0015em;
      color: #196EF5;
      margin-bottom: 0.25rem;
    }
  }

  .flight-detail {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .detail-card-wrapper {
    min-width: 165px;
    min-height: 106px;
    flex-grow: 1;
    max-width: 30%;
    display: flex;
    flex-direction: column;

    &:last-of-type {
      align-items: end;
    }

    .detail-card {
      background-color: $netral-2;
      padding: 0.75rem;
      display: flex;
      align-items: center;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      max-width: 205px;
      flex-grow: 1;

      &>div>div:first-of-type {
        color: #196EF5;
      }
    }
  }

  .flight-duration {
    text-align: center;
    flex-grow: 1;
    margin-inline: 2.75rem;

    .flight-arrow {
      margin: 0.25rem -5px;
      border-top: 1px solid $netral-10;
      min-width: 100%;
      position: relative;

      div {
        position: absolute;
        right: 0;
        border-bottom: 1px solid $netral-10;
        width: 5px;

        &:first-of-type {
          transform: rotate(45deg);
          top: -3px;
        }

        &:nth-of-type(2) {
          transform: rotate(135deg);
          top: 1px;
        }
      }
    }
  }

  &.vertical {
    flex-direction: column;
    gap: 0;
    position: relative;

    .timeline-item {
      width: 100%;

      .vertical-top {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-bottom: 2.5rem;

        .detail-card-wrapper {
          min-height: 0;
          flex-grow: 0;

          .detail-card {
            padding: 0;
            background-color: unset;
          }
        }
      }

      .card {
        border: 1px solid $netral-3;
        border-radius: 6px !important;
        margin-left: calc(30px + 1.5rem);
        margin-bottom: 2.5rem;
        box-shadow: none;

        .fac-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.0015em;
          color: $netral-10;
        }

        .fac-item {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: $netral-9;
        }

        i.fa-solid {
          color: $netral-10;
        }
      }

      .timeline-bullet {
        position: relative;
        width: 30px;
        height: 30px;
        background-color: white;
        border: 1px solid #4086F5;
        border-radius: 50%;

        &::after {
          content: '';

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 20px;
          height: 20px;
          background-color: #4086F5;
          border-radius: 50%;
        }
      }

      .timeline-track {
        content: "";
        position: absolute;
        left: 14px;
        display: block;
        border-left: 2px dashed $netral-4;
      }
    }
  }

  &.horizontal-2 {
    display: contents ;
    .flight-info-2{
      // gap: 32px;
      .person{
        background-color: $info-surface;
        border: 1px solid $info-border;
        gap: 0.375rem;
        display: flex;
        align-items: center;
        border-radius: 8px;
        width: fit-content;
        padding: 6px 12px;
      }
      .destination{
        background-color: $netral-2;
        border: 1px solid $netral-3;
        display: flex;
        align-items: center;
        border-radius: 8px;
        width: fit-content;
        padding: 6px 12px;
      }
      .special-price{
        background-color: $success;
        display: flex;
        align-items: center;
        border-radius: 6px;
        width: fit-content;
        padding: 6px 12px;
      }
    }   
  }
}