.modal {
    >.modal-dialog {
      >.modal-content {
        >.modal-header {
          background: $netral-1 !important;
          padding: 40px 30px 0px 0px;
  
          >.modal-title {
            font-weight: 500;
            font-size: 2rem;
            line-height: 42px;
            letter-spacing: 1px !important;
            color: #253863;
          }
          >.btn-close {
            border: 1px solid #CECECE;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            background-color: white;
            padding: 0;
            opacity: 1;
            margin-right: -45px;
            margin-top: -70px;
          }
        }
      }
    }
  }