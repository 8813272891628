#pembayaran-page {
    background: transparent;
    .card {
        margin: 7.688rem 16.875rem 0rem;
        padding: 24px;
        box-shadow: none;

        hr {
            margin-top: 28px;
            margin-bottom: 28px;
        }

        .row {
            padding-top: 20px;

            img {
                border: 1px solid $netral-3;
                border-radius: 6px;
                padding: 1rem;
                position: relative;
                width: 100%;
                aspect-ratio: 3/2;
                object-fit: contain;
            }

            .check-box {
                padding-top: 0.5rem;
            }

            .form-check-input {
                padding-top: 8px;
                border: 2px solid $netral-5;
            }
            .form-check-input:checked {
                background-color: $primary !important;
                background-clip: content-box !important;
                padding: 4px !important;
                border: 2px solid $primary !important;
              }

            
        }
    }


#section-accordion {

    .accordion {
        margin: 1.5rem 16.875rem ;
        background-color: $netral-1;
        border-radius: 10px 10px 10px 10px;    
        font-weight: bold;
        color: $netral-10 !important;
        .accordion-button {
            color: $netral-10;
            font-weight: bold;
            padding: 14px 24px;
            border: 1px solid $netral-5;
        }
        .accordion-collapse{
            border: 1px solid $netral-5;
        }
        .accordion-body {
            padding: 12px 36px ;

            .row {
                margin-bottom: 8px;
            }

            hr {
                margin: 0px 0px 8px 0px;
            }
        }
    }
}
}

    