.react-dataTable {
  overflow-y: visible !important;
  position: static !important;

  *:not(.dropdown-menu) {
    position: static !important;
  }

  .rdt_Table {
    overflow: visible !important;
    .rdt_TableHead {
      overflow-x: visible !important;
      overflow-y: visible !important;
      .rdt_TableHeadRow {
        background-color: #17488D;
        border: 1px solid $neutral-40;
        border-radius: 8px 8px 0 0;

        .rdt_TableCol {
          font-family: 'Lato';
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          // letter-spacing: 1px;
          color: #ffffff;
        }
      }
    }

    .rdt_TableBody {
      border: 1px solid $neutral-40;
      border-radius: 0 0 8px 8px;
      border-top: 0;
      >.rdt_TableRow {
        >.rdt_TableCell {
          &>div:not(.badge, .table-action) {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: $neutral-90;
          }
        }

        &:last-of-type {
          border-radius: 0 0 8px 8px;
        }
      }
    }

    .table-img {
      max-width: 62px;
      max-height: 62px;
      border-radius: 8px;
    }

    .table-action {
      a {
        border-radius: 4px;
        padding: 10px;
      }
    }
  }
}

.tableHeader {
  padding: 24px 24px 0px 24px;
}

.tableFeature {
  +* {
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  padding-top: 32px;
  padding-bottom: 16px;
}

.rdt_Pagination {
  border-top: none !important;
  margin-top: 32px;
  margin-bottom: 24px;
}

.btn-icon-table {
  width: 30px !important;
  border-radius: 4px !important;
}

.filter {
  margin-right: 12px;
}

.iconCursor:hover {
  cursor: pointer;
}

.table-header {
  margin-left: 24px;
  margin-right: 24px;
}


.btn-dropdown-table {
  border: 1px solid $netral-4 !important;
  background-color: $netral-1 !important;
  background-image: none !important;
  color: $netral-4 !important;

  &:hover {
    background-color: none !important;
  }

  &:active {
    background-color: none !important;
  }

  &:disabled {
    background-color: none !important;
  }

}

.sc-lllmON {
  &.gMliOo {
    margin-right: auto;
  }
}
