.navbar-nav.filter-navbar {
  gap: 2.5rem;

  .nav-link {
    display: flex;
    align-items: center;
    color: $netral-5 !important;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 22px;

    i {
      font-size: 20px;
      line-height: 0.05em;
      vertical-align: -0.075em;
      margin-right: 1rem;
    }

    &.active {
      color: $primary !important;
      background-color: transparent !important;
    }
  }
}
