#footer {
  .footer-control {
    padding: calc(1rem + 1.458vw) calc(1rem + 5.104vw) 1.5rem calc(1rem + 5.104vw);
    background-color: #FFFFFF;
  }

  .footer-logo {
    width: fit-content;
  }

  .footer-link {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: $netral-1;
    border: 1px solid $netral-4;
    border-radius: 8px;
    padding: 4px 10px 4px 10px;
  }

  .footer-button {
    padding-block: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .custom-button {
    background-color: $netral-1 !important;
    // border: 1px solid $primary !important;
    color: $primary !important;
    padding: 0px !important;
    transition: background-color 0.3s, color 0.3s !important;
    &:hover {
      background-color: $netral-1 !important;
      color: $netral-4 !important;
      box-shadow: none !important;
    }
  }
}
