#profile-page {
    background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);
    padding: 6.75rem 6.375rem;
    .warning {
        padding: 11px 19px;
        border-radius: 8px;
        margin-bottom: 2rem;
    }
    .card{
        padding: 2rem 2rem;
    }
}