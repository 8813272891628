.confirmation-modal {
  .modal-header {
    background: none;
    border-bottom: 1px solid $neutral-40;
    padding: 0.75rem 1.5rem;

    .header-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
  .modal-body {
    padding: 1.875rem;
  }
  .modal-footer {
    padding: 0.75rem 1.5rem;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.06);
  }
}
