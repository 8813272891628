#section-banner-manajemen {
  img {
    filter: brightness(80%);
    /* Mengubah gambar menjadi hitam-putih sepenuhnya */
  }

  .carousel-caption {
    h1 {
      position: absolute;
      top: 113px;
      line-height: 78px;
    }

    h6 {
      font-family: 'Libre Baskerville' !important;
      position: absolute;
      width: 100%;
      height: 60px;
      top: 260px;
      line-height: 30px;
    }
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  @media (max-width: 768px) {
    .carousel-caption {
      h1 {
        position: absolute;
        top: 113px;
        line-height: 50px;
      }

      h6 {
        font-family: 'Libre Baskerville' !important;
        position: absolute;
        width: 100%;
        height: 60px;
        top: 260px;
        line-height: 21px;
      }

    }
  }
}

#tim-manajemen-page {
  margin: 3rem 6.944vw 15rem;

  .nav-link.active:hover {
    background-color: transparent !important;
  }

  text-align: justify;

  .row {
    .col {
      img {
        width: 255px;
        height: 325px;
        display: block;
        position: relative;
        border-radius: 8px;
      }
    }
  }

  .input-group {
    margin-top: -105px !important;
  }

  .form-control {
    border-left: none !important;
  }
}
