#seacrh-result-page {
  position: relative;

  #form-penerbangan-search {
    position: fixed;
    background-color: $primary;
    margin-top: 4.45rem;
    height: 102px;
    padding: 16px 106px;
    top: 0;
    left: 0;
    z-index: 1;

    .form-subtitle {
      padding: 0rem !important;
      margin-bottom: 8px !important;
    }

    a {
      background-color: #ffffff !important;
    }

    .btn {
      --bs-btn-padding-x: 1rem !important;
      --bs-btn-padding-y: 8px !important;
      text-align: left;

    }

    .flatpickr-input {
      cursor: default !important;
      border: none !important;
    }

    .dropdown-penerbangan {
      border: 1px solid $netral-4;
      padding: 8px 1rem;
      width: 100%;
      border-radius: 6px;
    }

    .search-input-border {
      border-style: solid solid solid none !important;
    }

    .chevron-control {
      margin-left: auto;
      display: block;
    }

    .form-check-input:checked {
      background-color: $primary !important;
      background-clip: content-box !important;
      padding: 4px !important;
      border: 2px solid $primary !important;
    }

  }

  #search-page {
    background-color: $netral-2;
    // position: fixed;

    #section-row {
      padding-left: 106px;
      padding-right: 106px;
      padding-bottom: 32px;
      padding-top: 200px;

      .row {
        #filter {
          .section-filter {
            position: fixed;
            width: 20%;
            overflow-y: scroll;
            bottom: 20px;
            top: 198px;
            background-color: $netral-1;
            border-radius: 10px 10px 10px 10px;
            border: 1px solid $netral-3;

            hr {
              margin: 0px;
              padding: 0px;
            }

            .head {
              padding: 1rem 1.5rem;
            }

            .body {
              padding: 1rem 1.5rem;
            }

            img {
              padding-right: 20px;
              width: 35%;
            }

            .form-check-input {
              border: 2px solid $netral-5;
            }

          }
        }

        #ticket {
          // left: 400px;
          // top: 195px;
          // position: relative;

          .load {
            margin: 15rem
          }

          .section-flight {
            .card {
              padding: 1rem 1.5rem;
              background-color: $netral-1;
              box-shadow: none;
              border: 1px solid $netral-3;

              .line-through {
                text-decoration: line-through;
                color: $danger;
              }

              .person {
                background-color: $info-surface;
                border: 1px solid $info-border;
                gap: 0.375rem;
                display: flex;
                align-items: center;
                border-radius: 8px;
                width: fit-content;
                padding: 6px 12px;
                height: fit-content;
              }

              .destination {
                background-color: $netral-2;
                border: 1px solid $netral-3;
                display: flex;
                align-items: center;
                border-radius: 8px;
                width: fit-content;
                padding: 6px 12px;
              }

              .special-price {
                background-color: $success;
                display: flex;
                align-items: center;
                border-radius: 6px;
                width: fit-content;
                padding: 6px 12px;
              }
            }
          }
        }
      }

      .btn-sm {
        border-radius: 4px !important;
        padding: 8px 16px !important;
        font-size: 16px;
        line-height: 22px;
        width: 110px;
        height: 38px;
      }
    }
  }

  #alternatif-page {
    background-color: $netral-2;

    .card {
      margin: 2rem 6.438rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
      border-radius: 6px;
      background-color: $netral-1;

      .flight-alternative {
        gap: 10.188rem;
      }

      .special-price {
        background-color: $success;
        display: flex;
        align-items: center;
        border-radius: 6px;
        width: fit-content;
        padding: 6px 12px;
      }

      .person {
        background-color: $info-surface;
        border: 1px solid $info-border;
        gap: 0.375rem;
        display: flex;
        align-items: center;
        border-radius: 8px;
        width: fit-content;
        padding: 6px 12px;
      }

      .special-price {
        background-color: $success;
        display: flex;
        align-items: center;
        border-radius: 6px;
        width: fit-content;
        padding: 6px 12px;
      }

    }

  }

  #section-modal {
    .nav-link.active:hover {
      background-color: transparent !important;
    }
  }
}

#search-modal {
  margin-top: 150px !important;
  display: grid;
  justify-content: center;
  text-align: justify;

  .modal-content {
      width: 88.571rem !important;
      height: auto !important;
      .modal-body {
        #form-penerbangan {

          .btn {
            --bs-btn-padding-x: 1rem !important;
            --bs-btn-padding-y: 8px !important;
            text-align: left;
  
          }
  
          .btn-trash {
            padding-top: 28px;
            padding-right: 25px;
            padding-left: 25px;
            display: flex;
          }
  
          .btn-delete {
            border: 1px solid $netral-1 !important;
            color: $netral-10 !important;
            background-color: $netral-1 !important;
            padding: 0px;
  
            &:hover {
              background-color: $netral-1 !important;
              color: $danger !important;
              box-shadow: none;
            }
          }
  
          .icon-plus {
            padding-block: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.375rem;
          }
  
          .flatpickr-input {
            cursor: default !important;
            border: none !important;
          }
  
          .dropdown-penerbangan {
            border: 1px solid $netral-4;
            padding: 8px 1rem;
            width: 100%;
            border-radius: 6px;
          }
  
          .search-input-border {
            border-style: solid solid solid none !important;
          }
  
          .chevron-control {
            margin-left: auto;
            display: block;
          }
  
          .form-check-input:checked {
            background-color: $primary !important;
            background-clip: content-box !important;
            padding: 4px !important;
            border: 2px solid $primary !important;
          }
  
        }
      }
  }

}