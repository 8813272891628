#promo {
    margin: 6.75rem 6.25rem 6.563rem;
    background: transparent;

    #section-breadcrumb {
        padding: 0px 0px 32px;
    }

    .promo-header {
        background-color: white;
        padding: 2rem 1.5rem;
        border-radius: 8px;
    }

    .promo-filter {
        padding: 1.5rem;
        background-color: white;
        border-radius: 8px;

        .title {
            padding: 1.5rem;
        }

        .content {
            padding: 1.5rem;
        }
    }

    #promo-card-container {
        background-color: white;
        border-radius: 8px;
        padding: 1.5rem 3rem 7rem;


    }
}

#promo-card {

    min-width: 330px !important;
    max-width: 390px !important;
    // max-height: 11.875rem !important;
    .card-img-top {
        width: auto;
        object-fit: cover;
        height: auto;
        position: relative;
        width: 100%;
        aspect-ratio: 2/1;
      }
    .card-body-override {
        padding: 8px 0 !important;
        text-align: center;
    }

}



#promo-detail {
    margin: 6.75rem 6.25rem 6.563rem;

    #section-breadcrumb {
        padding: 0px 0px 32px;
    }

    #detail-content {
        padding: 1.5rem 1.5rem 3rem;
        background-color: white;
        width: 784px;
        height: 100%;
        border-radius: 8px;

        .img-override {
            width: 100% !important;
        }

        #kode-promo-card {
            box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            border-left: 8px solid #6EA2F5;
            padding: 1rem 1.5rem;

            #kode-promo-textfield {
                background-color: $netral-2;
                border: 1px solid $netral-4;
                border-radius: 6px;
                padding: 8px 12px;

            }
        }

    }

    #promo-lainnya {
        background-color: white;
        padding: 24px;
        border-radius: 8px;

    }
}