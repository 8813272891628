.navbar {
  // nyalakan untuk set warna dan posisi untuk navbar transparan
  background-color: rgba(0, 0, 0, 0) !important;
  position: fixed;

  .nav-link {
    font-weight: 500 !important;
    color: #FDFDFD !important;
    // matikan untuk color untuk navbar transparan
    // color: #646464 !important;
    margin-left: 0.5rem !important;
  }

  .nav-link-active {
    font-weight: 700 !important;
    // matikan untuk navbar transparan
    // color: #17488D !important;
    color: $primary !important;
    text-decoration: underline!important;
  }

  .nav-link:active {
    color: $primary!important;
  }

  .nav-link:hover {
    text-decoration: underline; /* Menambahkan garis bawah saat di-hover */
  }

  .navbar-item-spacing {
    margin-left: 0.358rem;
  }

  .navbar-container {
    display: none;
  }

  .navbar-dropdown-override {
    list-style-type: none;
  }

  .navbar-nav.collapsable-navbar {
    flex-flow: column;
    align-items: start !important;
    padding-block: 1rem;
  }

}

.navbar.color-change {
  background-color: rgb(255, 255, 255) !important;
  transition: background-color 200ms linear;
  box-shadow: 0px 2px 16px 2px rgba(23, 72, 141, 0.2) !important;

  .nav-link {
    font-weight: 500 !important;
    color: $primary !important;
    // matikan untuk color untuk navbar transparan
    // color: #646464 !important;
    margin-left: 0.5rem !important;
  }

  .nav-link-active {
    font-weight: 700 !important;
    // matikan untuk navbar transparan
    // color: #17488D !important;
    color: $primary !important;
  }

}

.header-navbar-shadow {
  display: none !important;
}

