.multi-filter {
    .form-control {
        border: 1px solid $netral-4;
    }
    &+.dropdown-menu {
      .multi-filter-footer {
        box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.06);
      }
    }
  }
.dropdown-menu{
  ul{
    padding: 0px;
  }
}
  