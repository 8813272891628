@import './variables';
@import 'base';

// @font-face {
//   font-family: 'Lato';
//   src: url('https://fonts.googleapis.com/css2?family=Lato');    
//        font-weight: 300 900;
//        font-display: swap;
//        font-style: normal;
// }

* {
	font-family: 'Lato', sans-serif ;
}

pre, code, kbd, samp {
  font-family: $font-family-monospace !important;
}

@import 'app';
@import 'react';

@import './dcn'
