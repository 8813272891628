// Shadow
.shadow-vs {
  box-shadow: 0px 2px 2px $shadow-vs !important;
}

.shadow-s {
  box-shadow: 0px 4px 4px 2px $shadow-s !important;
}

.shadow-m {
  box-shadow: 0px 4px 8px 4px $shadow-m !important;
}

.shadow-b {
  box-shadow: 0px 4px 12px 4px $shadow-b !important;
}

.shadow-vb {
  box-shadow: 0px 4px 20px 4px rgba(255, 255, 255, 0.25) !important;
}

.border-dashed {
  border-style: dashed !important;
}

// COLORS
@each $key,
$color in $dcn-colors {
  .text-#{$key} {
    color: $color !important;
  }

  .bg-#{$key} {
    background-color: $color !important;
  }

  .border-#{$key} {
    border: 1px solid $color !important;
    // border-color: $color !important;
  }
}

// LINE CLAMP
@each $size in $line-clamps {
  .line-clamp-#{$size} {
    display: inline;
    overflow: hidden;
    -webkit-box-orient: vertical;

    @supports (-webkit-line-clamp: $size) {
      display: -webkit-box;
      -webkit-line-clamp: $size;
    }
  }
}

.text-justify {
  text-align: justify !important;
}

.text-indent-1 {
  text-indent: 1rem !important;
}

.text-indent-2 {
  text-indent: 2rem !important;
}

.text-monospace {
  font-family: monospace !important;
}

.shadow-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, transparent 65%, #000000 135%);
}

// MD BREAKPOINT
@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}