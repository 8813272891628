.navbar-nav.stepper-navbar {
  gap: calc(2rem + 4.063rem);

  .nav-link {
    display: flex;
    align-items: center;
    color: $netral-5 !important;
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.0125em;
    pointer-events: none;
    user-select: none;
    position: relative;
    margin: 0 !important;
    padding: 0 !important;

    .step-track {
      position: absolute;
      top: 50%;
      left: calc(-4.063rem - 1rem);
      transform: translateY(-50%);
      display: block;
      height: 0;
      width: 4.063rem;
      border-top: 2px dashed $netral-4;
    }

    .stepper-number {
      color: $netral-1 !important;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.0015em;
      background-color: $netral-5;
      border-radius: 6px;
      height: 25px;
      width: 25px;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active,
    &.passed {
      color: $primary !important;
      background-color: transparent !important;

      .step-track {
        border-top: 2px dashed $primary;
      }

      .stepper-number {
        background-color: $primary;
      }
    }
  }
}
