#karir {
  #section-banner-karir {
    img {
      filter: brightness(80%);

    }

    .carousel-caption {
      h1 {
        position: absolute;
        top: 113px;
        line-height: 78px;
      }

      h6 {
        font-family: 'Libre Baskerville' !important;
        position: absolute;
        width: 100%;
        height: 60px;
        top: 260px;
        line-height: 30px;
      }
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    @media (max-width: 768px) {
      .carousel-caption {
        h1 {
          position: absolute;
          top: 113px;
          line-height: 50px;
        }

        h6 {
          font-family: 'Libre Baskerville' !important;
          position: absolute;
          width: 100%;
          height: 60px;
          top: 217px;
          line-height: 18px;
        }

      }
    }
  }

  #karir-page {
    margin: 6rem 6.944vw 4rem;

    .nav-link.active:hover {
      background-color: transparent !important;
    }

    text-align: justify;

    .row {
      margin-inline: 99px;

      .card {
        padding-bottom: 1rem;
        .card-title {
          padding: 1.5rem 1.667vw 0rem ;
        }
        .card-body {
          padding: 1.5rem 1.667vw 0rem ;
          .job {
            text-decoration: underline !important;
          }
        }

        .centered {
          width: calc(100% - 3rem);
          position: absolute;
          left: 1.5rem;
          bottom: 0.5rem;
          z-index: 2;
        }
      }
    }
    @media (max-width: 1024px) {
      .row {
        margin-inline: 0px;
      }
    }

    .input-group {
      margin-top: -105px !important;
    }

    .form-control {
      border-left: none !important;
    }
  }
}
