.custom-input-otp {
  >div {
    flex-wrap: wrap;

    input {
      background: #E7E7E7;
      box-shadow: 0px 2px 2px $shadow-vs;
      border-radius: 50%;
      border: none;
    }
  }
}
