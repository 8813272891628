#landing-page {
  background-color: #FFFFFF;

  .text-subtitle {
    font-family: 'Libre Baskerville';
    font-style: italic;
    // font-size: 18px;
    @include font-size(18px);
    line-height: 26px;
  }

  #section-banner {
    img {
      filter: brightness(80%);
      height: 752px;
    }

    .caption {
      h1 {
        line-height: 78px;
        text-align: center;
      }

      h6 {
        line-height: 30px;
        text-align: center;
        font-style: italic;
      }

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 113px 16.458vw;
    }

    .caption-home {
      h1 {
        line-height: 78px;
        text-align: center;
      }

      h6 {
        font-style: italic;
        line-height: 30px;
        text-align: center;
      }

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 240px 16.458vw;
    }

    .carousel-indicators-banner .carousel-indicators {
      margin-bottom: 135px !important;
    }

    @media (max-width: 768px) {
      img {
        filter: brightness(80%);
        height: 308px;
      }

      .caption {
        h1 {
          line-height: 36px;
          text-align: center;
          font-size: 20px;
          margin-bottom: 0.5rem;
        }

        h6 {
          line-height: 20px;
          text-align: center;
          font-style: italic;
          font-weight: 500;
          line-height: 18px;
          font-size: 12px
        }

        padding: 48px 1.111vw;
      }

      .caption-home {
        h1 {
          line-height: 36px;
          text-align: center;
          font-size: 20px;
          margin-bottom: 0.5rem;
        }

        h6 {
          line-height: 20px;
          text-align: center;
          font-style: italic;
          font-weight: 500;
          line-height: 18px;
          font-size: 12px
        }

        padding: 79px 1.111vw;
      }
    }
  }

  #section-form {
    z-index: 10;
    position: relative;

    .card {
      background-color: $netral-1 !important;
      border-radius: 16px;
    }

    .form-card-margin {
      margin: -300px 100px 0px;
      box-shadow: 1px 1px 6px rgba(12, 12, 12, 0.06);
    }

    .form-title {
      margin: 1rem 0 1rem 1.5rem !important;
    }

    .form-body-override {
      padding: 0 1.5rem 1rem !important;
    }

    .form-subtitle {
      padding: 0rem !important;
      margin-bottom: 8px !important;
    }

    .form-input {
      background-color: #ffffff !important;
    }

    .form-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;

      &.non-login {
        width: 11.438rem;
      }
    }

    #form-login {
      .card {
        background-color: $netral-1 !important;
        .card-body {
          padding: 8px 16px;
          .nav-link.active:hover {
            background-color: transparent !important;
          }
        }
      }

      #form-penerbangan {

        .btn {
          --bs-btn-padding-x: 1rem !important;
          --bs-btn-padding-y: 8px !important;
          text-align: left;

        }

        .btn-trash {
          padding-top: 28px;
          padding-right: 25px;
          padding-left: 25px;
          display: flex;
        }

        .btn-delete {
          border: 1px solid $netral-1 !important;
          color: $netral-10 !important;
          background-color: $netral-1 !important;
          padding: 0px;

          &:hover {
            background-color: $netral-1 !important;
            color: $danger !important;
            box-shadow: none;
          }
        }

        .icon-plus {
          padding-block: 0.5rem;
          display: flex;
          align-items: center;
          gap: 0.375rem;
        }

        .flatpickr-input {
          cursor: default !important;
          border: none !important;
        }

        .dropdown-penerbangan {
          border: 1px solid $netral-4;
          padding: 8px 1rem;
          width: 100%;
          border-radius: 6px;
        }

        .search-input-border {
          border-style: solid solid solid none !important;
        }

        .chevron-control {
          margin-left: auto;
          display: block;
        }

        .form-check-input:checked {
          background-color: $primary !important;
          background-clip: content-box !important;
          padding: 4px !important;
          border: 2px solid $primary !important;
        }

      }
    }

    @media (max-width: 768px) {
      .form-card-margin {
        margin: -80px 0px 0px;

        .nav-group {
          position: initial !important;
          overflow: auto;
          flex-wrap: nowrap;
          border-radius: 0;
        }

        .card {
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
        }
      }
    }
  }

  #section-promo {
    padding: 4.063rem 0;

    .row-promo {
      width: 80%;
      margin: auto;
      padding-top: 16px;
    }

    .card-promo {
      position: relative;
      height: 550px;
      border-radius: 8px;
    }

    .card-promo-sm {
      position: relative;
      height: 267px;
      border-radius: 8px;
    }

    .text-promo {
      text-align: justify;
      position: absolute;
      width: 100%;
      object-fit: cover;
      bottom: 0;
      padding-top: 16px;
      padding-left: 16px;
      padding-bottom: 24px;
      padding-right: 16px;
      background-color: #6B6B6B33;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .img-promo {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }


    .carousel {
      min-height: 550px;
      margin-bottom: 2rem;
    }
  }

  #section-solutions {
    padding: 2.625rem 0;
    background: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);


    .row {
      margin-inline: 3.889vw;
      --bs-gutter-x: 150px;

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 3/2;
        object-fit: contain;
      }

      div:first-of-type {
        text-align: justify;
      }
    }

    @media (max-width: 768px) {
      .row {
        margin-inline: 3.889vw;
        --bs-gutter-x: 0px;

        img {
          width: 50%;
          height: auto;
          aspect-ratio: 3/2;
          object-fit: contain;
        }

        .col {
          padding-right: 0 !important;
          padding-left: 0 !important;
        }
      }
    }

    @media (max-width: 1024px) {
      .row {
        margin-inline: 3.889vw;
        --bs-gutter-x: 0px;

        img {
          width: 50%;
          height: auto;
          aspect-ratio: 3/2;
          object-fit: contain;
        }

        .col {
          padding-right: 0 !important;
          padding-left: 0 !important;
        }
      }
    }

  }

  #section-products {
    padding: 2.625rem 0;

    .row {
      margin-inline: 3.889vw;
      --bs-gutter-x: 150px;

      img {
        width: 50%;
        height: auto;
        aspect-ratio: 3/2;
        object-fit: contain;
      }

      div:first-of-type {
        text-align: justify;
      }
    }

    .carousel {
      margin-bottom: 2rem;
      margin-top: 2rem;

      .carousel-item {
        .row {
          margin-inline: 3.889vw;
          --bs-gutter-x: 150px;

          img {
            width: 100%;
            height: auto;
            aspect-ratio: 3/2;
            object-fit: contain;
          }

          div:first-of-type {
            text-align: justify;
          }
        }

        @media (max-width: 768px) {
          .row {
            margin-inline: 3.889vw;
            --bs-gutter-x: 0px;

            img {
              width: 50%;
              height: auto;
              aspect-ratio: 3/2;
              object-fit: contain;
            }

            .col {
              padding-right: 0 !important;
              padding-left: 0 !important;
            }
          }
        }

        @media (max-width: 1024px) {
          .row {
            margin-inline: 3.889vw;
            --bs-gutter-x: 0px;
            img {
              width: 50%;
              height: auto;
              aspect-ratio: 3/2;
              object-fit: contain;
            }

            .col {
              padding-right: 0 !important;
              padding-left: 0 !important;
            }
          }
        }

      }

      @media (max-width: 425px) {
        .row {
          margin-inline: 0px;
        }
      }
    }

  }

#section-insight {
  padding: 2.625rem 0;

  .row {
    margin-inline: 99px;

    .card {
      padding-bottom: 1rem;
      border-radius: 16px 16px 0px 0px;

      .card-body {
        padding: 1.5rem 1.5rem 0rem 1.5rem;
      }

      .centered {
        width: calc(100% - 3rem);
        position: absolute;
        left: 1.5rem;
        bottom: 0.5rem;
        z-index: 2;
      }

      img {
        width: auto;
        object-fit: cover;
        height: auto;
        position: relative;
        width: 100%;
        aspect-ratio: 3/2;
      }
    }
  }

  @media (max-width: 425px) {
    .row {
      margin-inline: 0px;
    }
  }
}

#section-tentang-kami {
  margin-top: 2rem;
  min-height: 28rem;

  .tentang-kami-column {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    text-align: justify;

  }

  @media (min-width: 1024px) {
    .tentang-kami-column {
      padding-right: 6rem;
      padding-bottom: 9rem;
    }
  }

}

#section-testimonial {

  @media (max-width: 1024px) {
    .img-testimonial {
      border-radius: 50%;

      max-width: 50%;
      margin: auto;
    }

    .carousel-control-next,
    .carousel-control-prev {
      width: 20px;
    }
  }

  @media (min-width: 1024px) {
    padding: 2.188rem 0;
    background: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

    .carousel {
      min-height: 260px;
      margin-bottom: 1rem;
      margin-top: 3rem;
      padding-inline: 99px;
    }

    .row-testimonial {
      display: flex;
      margin-inline: 99px;
    }

    .row-testimonial {
      display: flex;
      margin-inline: 5px;
    }

    .column-testimonial {
      padding: 32px 42px;
      gap: 1.5rem;
      background: #FFFFFF;
      border: 1px solid $netral-3;
    }

    .img-testimonial {
      border-radius: 0;
      max-width: 100%;
      margin: 0;

      width: calc(4rem + 8.819vw);
      height: calc(4.063rem + 9.167vw);

      display: block;
      position: relative;
    }

    .icon-testimonial {
      position: relative;
    }

    .carousel-indicators-testimonial .carousel-indicators .active {
      background-color: transparent !important;
      position: relative;
    }
  }

}
}
