#pesanan-page {
    background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

    #section-table {
        margin: 7.688rem 6.25rem 0;

        .card {
            padding: 24px;
            box-shadow: none;
            border: 1px solid $netral-3;
           .card-body {

           }
        }
    }
}

#petunjuk-pembayaran {
    background: $warning-surface;
}

#accordion-pesanan {
    li {
        margin-bottom: 0.75rem !important;
        color: $netral-10 !important;
    }

    .accordion-button {
        color: $netral-10 !important;
        font-weight: bold;
        height: 50% !important;
        padding-top: 12px !important;
        padding-right: 24px !important;
        padding-bottom: 12px !important;
        padding-left: 24px !important;
        border: 1px solid $netral-5;
        margin-bottom: 24px !important;
        border-radius: 10px !important;
    }

    .accordion-button:hover {
        background-color: $netral-3 !important;
    }

    .accordion-button:not(.collapsed) {
        background-color: $netral-5 !important;
    }
}

#select-option-pesanan {
    width: 31% !important;
}


#letter-guarantee {
    background-color: linear-gradient(180deg, #F9F9F9 0%, #F9F9F9 90.51%);

    #section-breadcrumb {
        margin: 7.688rem 6.25rem 0;
        padding: 0;
    }

    #section-card {
      margin: 0 6.25rem 0;

        .card {
            padding: 24px;
            box-shadow: none;
            border: 1px solid $netral-3;

            .accordion {
                margin-bottom: 24px;
                background-color: $netral-1;
                border-radius: 10px 10px 10px 10px;
                font-weight: bold;
                color: $netral-10 !important;

                .accordion-button {
                    border: 1px solid $netral-5;
                    font-weight: bold;
                    padding: 14px 24px;
                    color: $netral-10 ;
                    display: flex;
                    align-items: center;
                    gap: 0.375rem;
                    height: 50% !important;

                    .form-check-input[type=checkbox] {
                        border-radius: 3px;
                        border-color: $primary;
                    }
                }

                .accordion-button:hover {
                    background-color: $netral-3 !important;
                }

                .accordion-button:not(.collapsed) {
                    background-color: $netral-5 !important;
                }

                .accordion-collapse {
                    border: 1px solid $netral-5;
                }

                .accordion-body {
                    padding: 12px 36px;

                    .form-check-input[type=checkbox] {
                        border-radius: 3px;
                        border-color: $primary;
                    }
                }
            }
        }
    }
}
