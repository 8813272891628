.nav.nav-group {
    position: absolute !important;
    margin-top: -40px !important;
    z-index:1;
    .nav-item {
        a.nav-link {
            padding: 0.75rem 1.5rem;
            background-color: $netral-1;
            // border: 1px solid $netral-5 !important;

            &.active {
                background-color: $netral-1 !important;
                font-weight: 600;
                color: $netral-10 !important;
            }
        }
        &:first-of-type {
            a.nav-link {
              border-top-left-radius: 10px;
            //   border-bottom-left-radius: 10px;

            }
        }
        &:last-of-type {
            a.nav-link {
              border-top-right-radius: 10px;
              background-color: $netral-2;
              color: $netral-7
            //   border-bottom-right-radius: 10px;
            }
        }
        &:not(:last-of-type) {
            a.nav-link {
                border-right: 1px solid $netral-3 !important;
                background-color: $netral-2;
                color: $netral-7
            }
        }
    }
}


