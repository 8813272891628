table.summary-table {
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
  }
  td.currency{
    text-align: end;
    font-size: 14px;
    line-height: 22px;
  }

  thead {
    tr {
      th {
        background-color: $primary !important;
        color: $netral-1;
        font-weight: 700;
        text-transform: capitalize !important;

        &:first-of-type {
          border-top-left-radius: 6px;
        }

        &:last-of-type {
          border-top-right-radius: 6px;
        }
      }
    }
  }

  tbody {
    tr {

      td {
        &:first-of-type {
          border-left: 1px solid #ebe9f1;
        }

        &:last-of-type {
          border-right: 1px solid #ebe9f1;
        }
      }

      &:not(.summary-row) {
        color: $netral-9;
      }

      &.summary-row {
        font-weight: 700;
        color: $netral-10;
        background-color: #F5F7FA;

        td {
          &:first-of-type {
            border-bottom-left-radius: 6px !important;
          }

          &:last-of-type {
            border-bottom-right-radius: 6px !important;
          }
        }
      }
    }
  }
}
