#informasi-pemesanan {
    margin: 7.688rem 6.25rem 0;
    background-color: transparent;

    #ringkasan-pembelian {
        .ringkasan-subtitle {
            margin-bottom: 0.5rem;
        }

        .ringkasan-textfield {
            background-color: $netral-2;
            border: 1px solid $netral-4;
            border-radius: 6px;
            padding: 0.5rem 0.75rem;
        }
    }

    #ringkasan-pesanan-anda {
        position: fixed;
        width: 29%;

        .btn-pesan {
            padding: 0.75rem 1rem !important;
            width: 9rem;
        }
        
        .form-input {
            background-color: white !important;
        }
    
        .input-group {
            .form-control {
                background-color: #fff !important;
                border-right: 0px;
            }
        }

    }

    #syarat-dan-ketentuan {
        #accordion-pesanan {
            margin-top: 0px !important;
        }
    }

    #detail-pemesanan{
        .card{
            #parent_div {
                display: grid;
                padding-top: 10px;
                padding-bottom: 16px;
                gap: 4px;
                grid-template-areas:
                  "div1 div2"
                  "div1 div3";
              }
          
              .detail-icon {
                grid-area: div1;
                width: fit-content;
                padding-right: 8px;
              }
          
              .detail-key {
                grid-area: div2;
                width: fit-content;
                padding-right: 8px;
              }
          
              .detail-info {
                grid-area: div3;
                width: fit-content;
                padding-right: 8px;
              }
          
              .icon-info {
                padding-block: 0.5rem;
                display: flex;
                align-items: center;
                gap: 0.375rem;
              }
        }
        
    }

}