#faq {
  #section-banner-faq {
    img {
      filter: brightness(80%);
      /* Mengubah gambar menjadi hitam-putih sepenuhnya */
    }

    .carousel-caption {
      h1 {
        position: absolute;
        top: 113px;
        line-height: 78px;
      }

      h6 {
        font-family: 'Libre Baskerville' !important;
        position: absolute;
        width: 100%;
        height: 60px;
        top: 260px;
        line-height: 30px;
      }

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    @media (max-width: 768px) {
      .carousel-caption {
        h1 {
          position: absolute;
          top: 113px;
          line-height: 50px;
        }

        h6 {
          font-family: 'Libre Baskerville' !important;
          position: absolute;
          width: 100%;
          height: 60px;
          top: 266px;
          line-height: 25px;
        }

      }
    }
  }

  #faq-page {
    margin: 6rem 6.944vw 4rem;

    .nav-link.active:hover {
      background-color: transparent !important;
    }

    text-align: justify;

    .card {
      .card-body {
        .job {
          text-decoration: underline !important;
        }
      }
    }

    .input-group {
      margin-top: -105px !important;
    }

    .form-control {
      border-left: none !important;
    }
  }
}

