.section-carousel{
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 42px 0px;
    gap: 16px;
    // height: 700px;
    left: 0px;
    top: 1633px;

}

.header-carousel{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px !important;
    gap: 8px !important;
    margin-top: 84px;
    margin-bottom: 40px;
    left: 300px !important;
    top: 35px !important;
}


.carousel-indicators [data-bs-target] {
    position: relative;
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 100%;
    background-color: $netral-2;
  }
.carousel-indicators [data-bs-target] span {
    content: ’’;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: inherit;
}

.carousel-indicators{
    margin-bottom: -25px;
}

.carousel-indicators .active {
    opacity: 1;
    background-color: $primary;
    position: relative;
    width: 17px;
    height: 10px;
    border: none;
    border-radius: 24px;
}

.carousel-control.left {
    margin-left: -25px;
}

.carousel-control.right {
    margin-right: -25px;
}
.carousel-control-prev {
    left: 0;
    background-image: none;
    width: 99px;
}
.carousel-control-next {
    right: 0;
    background-image: none;
    width: 99px;
}

