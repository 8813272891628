#konfirmasi-pembayaran-page {
    background-color: transparent;
    margin: 7.688rem 12.875rem;
    .accordion-button {
        color: $netral-10 !important;
        font-weight: bold;
        height: 50% !important;
        padding-top: 12px !important;
        padding-right: 24px !important;
        padding-bottom: 12px !important;
        padding-left: 24px !important;
        border: 1px solid $netral-5;
        margin-bottom: 24px !important;
        border-radius: 10px !important;
    }

    .accordion-button:hover {
        background-color: $netral-3 !important;
    }

    .accordion-button:not(.collapsed) {
        background-color: $netral-5 !important;
    }

    
    .card {
        padding: 32px 73.5px 64px 73.5px;
        box-shadow: none;
        border-radius: 10px;

        hr {
            margin-top: 28px;
            margin-bottom: 28px;
        }

        .badge {
            padding: 8px 20px;
            border-radius: 5px;
            position: relative;
        }

        .btn-copy {
            border: 1px solid $netral-1 !important;
            color: $netral-10 !important;
            background-color: $netral-1 !important;
            padding: 0px;

            &:hover {
                background-color: $netral-1 !important;
                color: $netral-4 !important;
                box-shadow: none;
            }
        }

        .vr {
            height: 100%;
            margin-left: 16px;
            margin-right: 16px;
            border: 1px solid $netral-10;
        }

        .form-select-lg {
            margin-bottom: 24px;

        }

        .btn-pemesanan {
            margin-top: 60px;
        }


    }
}